window.addEventListener("load", function () {
  var trainingsCarouselContainer = document.querySelectorAll(".blogCarousel");
  if (0 < trainingsCarouselContainer.length) {
    var trainingsCarousel = new Swiper(".blogCarousel", {
      direction: "horizontal",
      loop: false,
      spaceBetween: 8,
      slidesPerView: "auto",
      watchOverflow: true,
      breakpoints: {
        768: {
          spaceBetween: 16
        },
        1441: {
          spaceBetween: 46
        }
      },
      mousewheel: {
        enabled: true,
        forceToAxis: true
      },
      keyboard: true,
      navigation: {
        nextEl: ".blog-slider__navigation .swiper-button-next",
        prevEl: ".blog-slider__navigation .swiper-button-prev"
      },
      pagination: {
        el: ".blog-slider__pagination.swiper-pagination",
        clickable: true,
        type: "custom",
        renderCustom: function renderCustom(swiper, current, total) {
          return ("0" + current).slice(-2) + "/" + ("0" + total).slice(-2);
        }
      }
    });
  }
});